.Popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px #611515;
  z-index: 999; /* Ensure it appears above other elements */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  font-family: 'Source Code Pro', monospace;
}

.Popup.Submitted {
  box-shadow: 0 0 15px #156161; /* Change the color as needed */
}

.Title {
  text-align: center;
  font-family: 'Source Code Pro', monospace;
}

.Subtitle {
  font-size: 12pt;
  padding-top: 15px;
}

.Dropdown {
  margin-top: 20px; /* Adjust the margin to your preference */
}

.CloseButton {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 22px;
  color: #d30000;
  font-style: bold;
}

.CloseButton:hover {
  color: #611515; /* Change color on hover */
}

.SubmitButton {
  font-family: 'Source Code Pro', monospace; /* Apply the imported font */
  padding: 12px 24px;
  background-color: #611515;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2vh;
}

.SubmitButton:Hover {
  background-color: #942222;
}

.labelFontSize {
  margin-top: 10px;
}

.SearchBar2 {
  margin-left: -100px;
}