@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.FilterSection {
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
    padding-left: 5vw;
    padding-top: 2vh;
    accent-color: #156161;
}

.ReportCrimeButton {
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
    padding: 14px 26px;
    background-color: #611515;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -1vh;
    margin-left: 0vw;
  }

.crimeText {
  margin-top: 1vh;
}

.ReportCrimeButton:Hover {
    background-color: #942222;
  }

.reportContainer {
  width: 300px; /* Set your desired width */
  height: auto;
  padding: 15px; /* Optional: Add some padding for spacing */
  padding-top: 0%;
  word-wrap: break-word; /* This property allows breaking long words */
  margin-left: 0vw;
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  border: 2px solid #9c9c9c; /* Include the border style (solid, dashed, dotted, etc.) */
  border-radius: 10px;
}

.reportContainer-Container {
  padding-top: 2%; /*test change */
}
