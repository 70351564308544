.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0); 
    color: rgb(0, 0, 0);
    padding: 20px;
    padding-top: 25px;
    z-index: 3;
  }

.header-links {
    display: inline-flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 2px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
  
  .header-links li {
    flex: 1;
    margin: 0;
    padding-right: 40px;
  }

  .logoImage {
    width: 190px; 
    height: auto;
    padding-left: 45vw;
  }

  .login-link{
    width: 120px; 
    padding-right: 2vw;
  }

  .signedin-link{
    width: 150px; 
    padding-right: 2vw;
  }

  .header-links li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  
  .header li a:hover {
    color: rgb(156, 156, 156); 
  }

.header-links-right {
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.header-links-right li a {
  color: rgb(8, 8, 8);
  text-decoration: none;
  padding-right: 1vw;
}
