.map-container {
  position: absolute;
  top: -0.9vh;
  right: 0;
  bottom: 0;
  left: 29vw;
  border: 2px solid rgb(0, 0, 0);
}

.map-container > div {
  width: 100%;
  height: 100%;
}

.icons {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 350px;
  z-index: 1; /* Ensure the image is above the map */
  border: 2px solid rgb(0, 0, 0);
  border-radius: 10px;
}

.marker {
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  background-size: cover;
  cursor: pointer;
}

/* Additional styles for the popup if needed */

.maplibregl-marker > svg {
  display: none;
}

.arson-marker {
  background-image: url('https://opencrime.io/resources/icons/arson.png');
}

/* Styles for Assault marker */
.assault-marker {
  background-image: url('https://opencrime.io/resources/icons/assault.png');
}

/* Styles for Burglary marker */
.burglary-marker {
  background-image: url('https://opencrime.io/resources/icons/burglary.png');
}

.homicide-marker {
  background-image: url('https://opencrime.io/resources/icons/homicide.png');
}

.motor-vehicle-theft-marker {
  background-image: url('https://opencrime.io/resources/icons/motorvehicletheft.png');
}

.robbery-marker {
  background-image: url('https://opencrime.io/resources/icons/robbery.png');
}

.shots-fired-marker {
  background-image: url('https://opencrime.io/resources/icons/shotsfired.png');
}

.suspicious-activity-marker {
  background-image: url('https://opencrime.io/resources/icons/suspicious.png');
}
/* Styles for Other marker */
.other-marker {
  background-image: url('https://opencrime.io/resources/icons/other.png');
}


.map-container .map-checkbox-label input[type="checkbox"] {
  margin-left: 5px;
}
