.LocationPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgb(255, 8, 255);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .LocationPopup p {
    text-align: center;
    font-family: 'Source Code Pro', monospace;
  }
  
  .CloseButton {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    color: #d30000;
    font-style: bold;
  }
  
  .CloseButton:hover {
    color: #611515;
  }