.home-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .landing-logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .LandingButton {
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
    padding: 12px 24px;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #156161;
    color: #fff;
    border: none; 
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    font-size: 20px;
  }

  .LandingButton:hover {
    background-color: #229696;  
  }