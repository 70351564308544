@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

/* Styles for the SearchBar component */
.SearchBar {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin: 10vh 5vw 0 5vw;
  margin-right: 200px;
}

/* Apply styles directly to the input element */
.SearchBar input {
  flex: 1;
  padding: 11px;
  border: 1px solid #000000;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Source Code Pro', monospace;
  margin-right: 8px;
  &:focus {
    border-color: #156161; /* Change the border color when focused */
    border-width: 2px; 
    outline: none; /* Remove the default outline */
  }
}

/* Styles for the SearchButton */
.SearchButton {
  padding: 13px 17px;
  background-color: #156161;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 35px;
  font-family: 'Source Code Pro', monospace;
}

.SearchButton:hover {
  background-color: #229696;  
}

/* Styles for react-autosuggest suggestions */
.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  padding: 12px;
  border: 1px solid #000000;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Source Code Pro', monospace; /* Apply the imported font */
}

.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  background-color: #ffffff;
}

.react-autosuggest__suggestions-container--open {
  display: block; /* Change from none to block when open */
}

/* Styles for react-autosuggest suggestion items */
.react-autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
  list-style-type: none; /* Remove bullet points */
  font-family: 'Source Code Pro', monospace;
  background-color: #ffffff;
  margin-left: -40px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ffffff;
}