@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.body {
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 100vh;
}

.MapButton {
    position: absolute;
    margin: -30vh 5vw 0 -5vw; 
    padding: 10px; /* Optional: Add padding for styling */
    background-color: #156161; /* Set your desired background color */
    color: white; /* Set your desired text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Source Code Pro', monospace;
}

.resetMapButton:hover {
    background-color: #229696;  
}

.dancingGIF {
    margin-top: 0vh;
    margin-left: 0vw;
    height: 150px;
}

.supportContainer {
    position: absolute;
    width: 330px; /* Set your desired width */
    padding: 5px; /* Optional: Add some padding for spacing */
    word-wrap: break-word; /* This property allows breaking long words */
    margin-left: 115px;
    margin-top: -127px;
    text-align: center;
    font-family: 'Source Code Pro', monospace;
}

.paypal {
    color: #156161;
}

.megaContainer {
    margin-top: 4vh;
    padding: 5px;
    margin-left: 3vw;
    width: 460px; /* Set your desired width */
    height: 150px;
    border: 2px solid rgb(201, 201, 201); /* Include the border style (solid, dashed, dotted, etc.) */
    border-radius: 10px;
}