.SearchBar2 {
    display: flex;
    align-items: center;
    max-width: 300px;
    height: 30px; /* Adjust the height as needed */
    margin: 2vh 0 0 -1vw; /* Adjust as needed for the desired position */
  }
  
  .SearchInput2 {
    width: 100%;
    padding: 8px; /* Adjust padding for better spacing */
    border: 1px solid #000000;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
  }
  
  /* Styles for react-autosuggest suggestions */
  .react-autosuggest__container {
    position: center;
    width: 100%;
  }
  
  .react-autosuggest__input {
    width: 100%;
    padding: 8px; /* Adjust padding for better spacing */
    border: 1px solid #000000;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
  }
  
  .react-autosuggest__suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #f2f2f2;
  }