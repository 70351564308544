.textbox-container {
    position: relative;
    width: 300px;
    margin: 10px;
  }
  
  .textbox {
    width: calc(100% - 20px);
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    resize: none; /* Disable textarea resizing */
  }
  
  .character-count {
    position: absolute;
    bottom: 5px;
    right: 7px;
    color: #555;
    font-size: 12px;
  }