.PopupSignedIn {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgb(255, 8, 255);
    z-index: 999; /* Ensure it appears above other elements */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    justify-content: center; /* Center the content vertically */
  }
  
  .TitleSISU {
    text-align: center;
    font-family: 'Source Code Pro', monospace;
  }
  
  .Subtitle {
    font-size: 12pt;
    padding-top: 15px;
  }
  
  .Dropdown {
    margin-top: 20px; /* Adjust the margin to your preference */
  }
  
  .CloseButton {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    color: #d30000;
    font-style: bold;
  }
  
  .CloseButton:hover {
    color: #611515; /* Change color on hover */
  }
  

  .SignOutButton {
    font-family: 'Source Code Pro', monospace; /* Apply the imported font */
    padding: 12px 24px;
    background-color: #861d81;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 2vh;
  }

  .SignOutButton:Hover {
    background-color:  #b12baa;
  }

  .textbox-containerSISUemail {
    position: relative;
    width: 300px;
    margin: 10px;
  }

  .textboxSISUemail {
    width: calc(100% - 20px);
    height: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    resize: none; /* Disable textarea resizing */
  }

  .textbox-containerSISUpass {
    position: relative;
    width: 300px;
    margin: 10px;
  }


  .textboxSISUpass {
    width: calc(100% - 20px);
    height: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    resize: none; /* Disable textarea resizing */
  }

  .button-line {
    border-top: 1px solid rgb(112, 112, 112); /* Use black color for the line */
    width: 78%;
    margin-top: 6vh;
  }

  .CaptchaIncompleteMessage {
    color: red;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }

  .AccountCreatedMessage {
    color: #156161;
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
    margin-top: 4vh;
    margin-bottom: -5px;
  }

  .ErrorMessage {
    color: red;
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
    margin-top: 4vh;
    margin-bottom: -5px;
  }